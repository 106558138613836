export interface ErrorType {
    lastname?: string | string[];
    username?: string | string[];
    firstname?: string;
    password?: string;
    confirmedPassword?: string;
    terms?: string;
    contactPhone?: string;
    currentPassword?: string;
}

export enum ValidationLength {
    min = 8,
    max = 100,
}

const validatePassword = (password: string, isLogin: boolean): string | any => {
    let errorMessage = '';

    if (!password) {
        errorMessage = 'Please enter a password';
    } else if (password.length > ValidationLength.max && !isLogin) {
        errorMessage = 'Your password cannot exceed 100 characters';
    } else if (
        !isLogin &&
        (!/^(?=.*[0-9_])(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\s])([a-zA-Z0-9_]|[^\w\s])*$/.test(
            password
        ) ||
            password.length < 8)
    ) {
        errorMessage = `Too short. Please enter at least 8 characters.
            Include at least one capital letter, one lowercase letter, one number and one special character
            e.g. !@#$£%^*-_+=`;
    } else {
        /** password passed all validation conditions */
        return '';
    }

    return errorMessage;
};

export const validateEmail = (email: string): string => {
    let errorMessage = '';

    if (!email) {
        errorMessage = 'Please enter a valid email address';
    } else if (!/^[A-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[A-Z0-9]([A-Z0-9-]*[A-Z0-9])?(\.[A-Z0-9]([A-Z0-9-]*[A-Z0-9])?)*(\.[A-Z]{2,})?$|^\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\]$/i.test(email)) {
        errorMessage = 'Please enter a valid email address';
    } else if (email.length > ValidationLength.max) {
        errorMessage = 'The email address cannot exceed 100 characters';
    } else {
        /** email passed all validation conditions */
        return '';
    }

    return errorMessage;
};

export const validateTerms = (isUnchecked: boolean): ErrorType => {
    const errors: ErrorType = {};
    if (isUnchecked) {
        errors.terms = 'You must agree to continue';
    }
    return errors;
};

export const validatePhone = (phone: number): string => {
    let errorMessage = '';
    if (phone?.toString().length < 7) {
        errorMessage = 'Please enter a phone number over 6 numbers';
    }
    return errorMessage;
};

export const validatePasswordMatch = (password: string, confirmedPassword: string): string => {
    let errorMessage = '';
    if (password !== confirmedPassword) {
        errorMessage = 'Your passwords need to match';
    }
    return errorMessage;
}

const validate = (values, eventTarget?, isLogin?) => {
    const errors: ErrorType = {};
    const target = eventTarget?.toLowerCase();

    if (target === 'firstname' || target === 'all') {
        if (!values.firstname?.trim()) {
            errors.firstname = 'Please enter your first name';
        } else if (values.firstname?.trim().length > ValidationLength.max) {
            errors.firstname = 'The first name cannot exceed 100 characters';
        }
    }

    if (target === 'lastname' || target === 'all') {
        if (!values.lastname?.trim()) {
            errors.lastname = 'Please enter your last name';
        } else if (values.lastname?.trim().length > ValidationLength.max) {
            errors.lastname = 'The last name cannot exceed 100 characters';
        }
    }

    if (target === 'login' || target === 'username' || target === 'email' || target === 'all') {
        const errorMessage = validateEmail(values.username?.trim());

        if (errorMessage) {
            errors.username = errorMessage;
        }
    }

    if (target === 'contactphone' || target === 'all') {
        const errorMessage = validatePhone(values.contactPhone?.trim());

        if (errorMessage) {
            errors.contactPhone = errorMessage;
        }
    }

    if (target === 'login' || target === 'password' || target === 'confirmedPassword' || target === 'all') {
        const errorMessage = validatePassword(values.password?.trim(), isLogin);

        if (errorMessage) {
            errors.password = errorMessage;
        }

        if (target === 'confirmedPassword' && values.password !== values.confirmedPassword) {
            errors.confirmedPassword = 'Passwords do not match';
        }
    }

    return errors;
};

export default validate;

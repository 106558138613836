import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Icon } from '@gumtree/ui-library';
import FormElement from '@gumtree/ui-library/src/form/form-element';
import { qaAttribute } from '@gumtree/ui-library/src/utils/qa-service';
import { sanitizeString } from '@gumtree/ui-library/src/utils/string-service';
import { trackGA4Async } from '@gumtree/shared/src/util/ga4-shared';

import { Device } from '@gumtree/shared/src/types/client-data';
import Input from '@gumtree/ui-library/src/form/input';
import { getDistanceFromUserPref } from '@gumtree/shared/src/util/user-pref-cookie-service';
import KeywordSearchField from './keyword-search-field/keyword-search-field-container';
import LocationField from './location-field/location-field-container';
import {
    setupSuggestionsService,
    submitSearch,
    getSearchFormat,
    SEARCH_FORMATS,
} from './search-bar-service';
import appBannerCookieStorage from './app-banner-cookie-storage';
import './opensearch.xml';
import { toggleSearchModal } from './search-bar-actions';
import { SearchModal } from './mobile-modal/modal';
import { OnlyShowOnLargeScreen, OnlyShowOnSmallScreen } from '../utils/screens.style';
import { searchFieldPlaceholderText } from './constants';
import {
    SearchBarKeywordSelection,
    SearchBarKeywordState,
} from './keyword-search-field/keyword-reducer';
import {
    SearchBarLocationSelection,
    SearchBarLocationState,
} from './location-field/location-reducer';
import { searchBarContainerCss } from './search-bar.style';

interface SearchBarProps {
    device: Device | undefined;
    popularSearches: {
        showPopularSearches: boolean;
        queryKeywords?: Array<{ name: string; url: string }>;
    };
    isQA?: boolean;
    algoliaKey: string;
    keyword?: SearchBarKeywordState;
    selectedKeyword?: SearchBarKeywordSelection;
    selectedLocation?: SearchBarLocationSelection;
    location?: SearchBarLocationState;
    showSearchModal: boolean;
    showSearchBarOnMobile: boolean;
    outsideFocus?: boolean;
}

export const trackSearchEvent = async ({
    sanitizedKeyword,
    sanitizedLocation,
    selectedKeyword,
    selectedLocation,
}: {
    sanitizedKeyword?: string;
    sanitizedLocation?: string;
    selectedKeyword?: SearchBarKeywordSelection;
    selectedLocation?: SearchBarLocationSelection;
}) => {
    const searchPayload = {
        format: getSearchFormat({
            term: sanitizedKeyword,
            location: sanitizedLocation,
        }),
        term: sanitizedKeyword || undefined,
        category: selectedKeyword?.category,
        location: sanitizedLocation || undefined,
    };

    await trackGA4Async<GA4.SubmitListingSearchEvent | GA4.SubmitSuggestedListingSearchEvent>(
        selectedKeyword?.name || selectedLocation?.name
            ? {
                  event: 'submit_suggested_listing_search',
                  search: searchPayload,
                  searchSuggestion: {
                      type: selectedKeyword?.name
                          ? SEARCH_FORMATS.BY_TERM
                          : SEARCH_FORMATS.BY_LOCATION,
                      term: selectedKeyword?.name! || selectedLocation?.name!,
                  },
              }
            : {
                  event: 'submit_listing_search',
                  search: searchPayload,
              }
    );
};

export const submitSearchHandler = async ({
    setIsSearchSubmitted,
    currentKeyword,
    selectedKeyword,
    selectedLocation,
    currentLocation,
    requireKeywordCorrection = false,
}: {
    setIsSearchSubmitted: (value: boolean) => void;
    currentKeyword: string | undefined;
    selectedKeyword: SearchBarKeywordSelection | undefined;
    selectedLocation: SearchBarLocationSelection | undefined;
    currentLocation: string | undefined;
    requireKeywordCorrection: boolean;
}) => {
    setIsSearchSubmitted(true);
    const submittableKeyword = selectedKeyword?.name ? selectedKeyword?.name : currentKeyword;
    const submittableLocation = selectedLocation?.name ? selectedLocation?.name : currentLocation;

    const sanitizedKeyword = submittableKeyword
        ? sanitizeString(submittableKeyword)
        : submittableKeyword;
    const sanitizedLocation = submittableLocation
        ? sanitizeString(submittableLocation)
        : submittableLocation;

    await trackSearchEvent({
        sanitizedKeyword,
        sanitizedLocation,
        selectedKeyword,
        selectedLocation,
    });

    const distance = getDistanceFromUserPref();

    submitSearch({
        category: selectedKeyword?.category,
        categoryDisplayName: selectedKeyword?.categoryDisplayName,
        keyword: sanitizedKeyword,
        location: sanitizedLocation,
        requireKeywordCorrection,
        searchOptionsExactMatch: Boolean(selectedKeyword?.searchOptionsExactMatch),
        additionalParams: {
            ...(distance ? { distance } : {}),
        },
    });
    appBannerCookieStorage();
};

const SearchBar: React.FC<SearchBarProps> = ({
    algoliaKey,
    showSearchModal,
    isQA = false,
    keyword,
    location,
    popularSearches,
    showSearchBarOnMobile,
    outsideFocus,
}) => {
    const dispatch = useDispatch();

    const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

    const handleToggleSearchDialog = () => {
        dispatch(toggleSearchModal());
    };

    useEffect(() => {
        setupSuggestionsService(isQA, algoliaKey);
    }, [isQA, algoliaKey]);

    useEffect(() => {
        if (keyword?.selectedSuggestion || location?.selectedSuggestion) {
            submitSearchHandler({
                setIsSearchSubmitted,
                currentKeyword: keyword?.value,
                selectedKeyword: keyword?.selectedSuggestion,
                selectedLocation: location?.selectedSuggestion,
                currentLocation: location?.value,
                requireKeywordCorrection: false,
            });
        }
    }, [keyword?.selectedSuggestion, location?.selectedSuggestion]);

    return (
        <div className="search-bar-container" css={searchBarContainerCss}>
            <Form
                action="/search"
                onSubmit={() => {
                    submitSearchHandler({
                        setIsSearchSubmitted,
                        currentKeyword: keyword?.value,
                        selectedKeyword: keyword?.selectedSuggestion,
                        selectedLocation: location?.selectedSuggestion,
                        currentLocation: location?.value,
                        requireKeywordCorrection: true,
                    });
                }}
                data-testid="submit-form"
            >
                <div className="search-bar" {...qaAttribute('search-bar')}>
                    <SearchModal isOpen={showSearchModal} onClose={handleToggleSearchDialog} />

                    {showSearchBarOnMobile && (
                        <OnlyShowOnSmallScreen>
                            <div className="inputs-container--fake-search">
                                <FormElement type="input" className="input-field">
                                    <div className="icon-container">
                                        <Icon type="magnifying-glass" size="medium" />
                                    </div>
                                    <Input
                                        placeholder={searchFieldPlaceholderText}
                                        autoComplete="off"
                                        className="input"
                                        id="search-bar-input"
                                        name="search-bar-input"
                                        onClick={handleToggleSearchDialog}
                                        value={keyword?.value || ''}
                                    />
                                </FormElement>
                            </div>
                        </OnlyShowOnSmallScreen>
                    )}

                    <OnlyShowOnLargeScreen>
                        <div className="inputs-container--real-search">
                            <div className="keyword-search-container">
                                <KeywordSearchField outsideFocus={outsideFocus} />
                            </div>
                            <div className="dividing-line" />
                            <div className="location-container">
                                <LocationField />
                                <Button
                                    className="search-button"
                                    display="primary"
                                    isLoading={isSearchSubmitted}
                                    loadingText=""
                                    label={<Icon type="magnifying-glass" />}
                                    type="submit"
                                    {...qaAttribute('search-button')}
                                />
                            </div>
                        </div>
                    </OnlyShowOnLargeScreen>

                    {popularSearches.showPopularSearches && popularSearches.queryKeywords && (
                        <div className="popular-search-wrapper">
                            <input type="checkbox" id="popular-search-container-input" hidden />
                            <ul className="popular-search-container">
                                <li className="popular-search-container-title">Top searches: </li>
                                {popularSearches.queryKeywords.map((el) => (
                                    <li key={el.name}>
                                        <a href={el.url}>{el.name}</a>
                                    </li>
                                ))}
                            </ul>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                            <label
                                htmlFor="popular-search-container-input"
                                className="expand-label"
                            >
                                <Icon type="dropdown" />
                            </label>
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default SearchBar;

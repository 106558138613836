import logger from './logger';

export async function postData(
    formData: Record<string, string>,
    setIsLoading: (loading: boolean) => void,
    url: string,
    type: 'POST' | 'PUT' | 'GET' | 'DELETE' = 'POST'
): Promise<any> {
    setIsLoading(true);
    try {
        const response = await fetch(url, {
            method: type,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'cors',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: new URLSearchParams(formData as any),
        });
        const data = await response.json();
        return data;
    } catch(error) {
        setIsLoading(false);
        logger.error('Post data error:', error);
    }
}

export async function resendLink(url) {
    try {
        const response = await fetch(`/bff-api/register/resend-activation?resendLink=${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        return data;
    } catch (error) {
        logger.error('Resend link error:', error);
    }
}

import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import classnames from 'classnames';

import './icon.scss';

export const iconColors = [
    'white',
    'dark',
    'light',
    'grey',
    'lightGrey',
    'newGrey',
    'darkGrey',
    'pink',
    'yellow',
    'newBrandYellow',
    'blue',
    'green',
    'red',
    'textPrice',
    'default',
    'darkGreen',
    'blade',
];

export type IconColor = typeof iconColors[number];

interface IconProps {
    className?: string;
    id?: string;
    color?: IconColor;
    size?: 'large' | 'medium' | 'medium-small' | 'base' | 'small' | 'smaller' | 'smallest';
    type: string;
    onClick?: MouseEventHandler;
}
const Icon = ({ className, id, color, size, type, onClick, ...props }: IconProps) => {
    const classes = classnames(
        'icon',
        {
            [`icon--color-${color}`]: color,
            [`icon--${size}`]: size,
            [`icon--${type}`]: type,
        },
        className
    );

    return <span onClick={onClick} className={classes} id={id} aria-hidden {...props} />;
};

export default styled(Icon)``;

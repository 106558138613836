import styled from '@emotion/styled';
import {
    zIndexes,
    colorVariables,
    boxSizes,
    gutterSizes,
    fontWeights,
    mediaQuery,
    breakpoints,
} from '../base/theme';
import Button from '../button/button';

const DIALOG_ICON_SIZE = '18px';
const DIALOG_MAX_WIDTH = '600px';
const DIALOG_MIN_WIDTH = '330px';

export const DialogContainer = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ${zIndexes.high};
`;

export const DialogWindow = styled.div<{ displayAsSheetOnMweb: boolean }>`
    background-color: ${colorVariables.backgroundNeutral};
    border: ${boxSizes.borderWidth} solid ${colorVariables.borderLight};
    border-radius: ${boxSizes.borderRadius};
    margin: ${gutterSizes.large};
    max-width: ${DIALOG_MAX_WIDTH};
    min-width: ${DIALOG_MIN_WIDTH};
    padding: ${gutterSizes.large};
    position: relative;
    box-sizing: border-box;
    max-height: 100%;
    overflow: auto;

    ${({ displayAsSheetOnMweb }) =>
        displayAsSheetOnMweb &&
        `
    ${mediaQuery.until(breakpoints.medium)} {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        max-width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    `};
`;

export const DialogTitle = styled.h2`
    margin: 0 0 ${gutterSizes.large} 0;
    padding-right: ${DIALOG_ICON_SIZE};
`;

export const DialogClose = styled(Button)`
    && {
        padding: 0;
        width: ${boxSizes.boxHeight};
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            color: ${colorVariables.fontTeritary};
            font-size: ${DIALOG_ICON_SIZE};
            font-weight: ${fontWeights.bold};
        }
    }
`;

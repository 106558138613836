import { PageType } from '@gumtree/shared/src/types/client-data';

export const isAdSenseEnabled = (pageType: PageType, isGTF2602On: boolean) => {
    return (pageType === 'ResultsBrowse' || pageType === 'ResultsSearch') && isGTF2602On;
};

const libraryTag = () =>
    `<script async="async" src="https://www.google.com/adsense/search/ads.js"></script>`;

export const initialisationTag = (pageType: PageType, isGTF2602On: boolean) =>
    isAdSenseEnabled(pageType, isGTF2602On)
        ? `
      ${libraryTag()}
      <script type="text/javascript" charset="utf-8">
      (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
      arguments)},g[o]['t']=1*new Date})(window,'_googCsa');
      </script>
      `
        : '';

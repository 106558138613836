import styled from "@emotion/styled";
import {
    colorVariables,
    colors,
    fontSizes,
    fontWeights,
    gutterSizes,
    mediaQuery,
    breakpoints,
} from "@gumtree/ui-library/src/base/theme";
import Eye from "@gumtree/ui-library/src/assets/eye.png";
import NoEye from "@gumtree/ui-library/src/assets/eye-closed.png";
import Tick from "@gumtree/ui-library/src/assets/green-checkmark.png";
import { Button, Link } from "@gumtree/ui-library";

const shouldForwardProp = (p: string) => !["isPasswordVisible", "isOnBottom"].includes(p);

export const FormTitle = styled.h2`
    font-size: ${fontSizes.pgLarge};
    font-weight: ${fontWeights.bold};
    color: ${colorVariables.textPrimary};

    ${mediaQuery.until(breakpoints.medium)} {
        font-size: ${fontSizes.xlarge};
    }
`;

export const GroupedInput = styled.div`
    ${mediaQuery.from(breakpoints.small)} {
        display: flex;
        gap: ${gutterSizes.large};
    }
`;

export const FormContainer = styled.div`
    .form-element--input {
        text-align: left;
        margin-bottom: ${gutterSizes.large};
    }

    input {
        margin-top: ${gutterSizes.small};
        border-radius: ${gutterSizes.small};
    }

    .error-messages {
        font-size: ${fontSizes.small};
    }

    label {
        font-weight: ${fontWeights.normal};
        font-size: ${fontSizes.small};

        &.error-label {
            color: red;
        }

        &.error-checkbox {
            margin-bottom: 0;
        }
    }

    .button--primary {
        margin-top: ${gutterSizes.xlarge};
        font-weight: ${fontWeights.bold};
    }
`;

export const PasswordContainer = styled("div", {
    shouldForwardProp,
})<{
    isPasswordVisible?: boolean;
    isCurrentPassword?: boolean;
}>`
    .reveal-button-show {
        height: ${gutterSizes.xlarge};
        width: ${gutterSizes.xlarge};
        min-width: ${gutterSizes.xlarge};
        right: ${gutterSizes.medium};
        top: 36px;
        background-repeat: no-repeat;
        font-size: 0;
    }
    .form-element--input {
        margin-bottom: ${gutterSizes.small};
    }

    .reveal-button-show {
        background-image: ${({ isPasswordVisible }) =>
            isPasswordVisible ? `url(${Eye})` : `url(${NoEye})`};
    }

    ${({ isCurrentPassword }) => isCurrentPassword && `padding-bottom: ${gutterSizes.medium}`};
`;

export const CheckboxContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    .error-messages {
        margin-left: ${gutterSizes.xxlarge};
    }

    .link {
        font-size: ${fontSizes.base};
        font-weight: ${fontWeights.bold};
    }

    .control {
        display: block;
        position: relative;
        padding-left: ${gutterSizes.xxlarge};
        margin-bottom: ${gutterSizes.medium};
        padding-top: ${gutterSizes.small};
        cursor: pointer;
        font-size: ${fontSizes.base};
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked ~ .control_indicator {
            background: ${colors.green};
        }
    }
    .control_indicator {
        position: absolute;
        top: 5px;
        left: 0;
        height: 16px;
        width: 16px;
        background: ${colors.white};
        border: 1px solid ${colors.green};
        border-radius: 3px;

        &:after {
            box-sizing: unset;
            content: "";
            position: absolute;
            display: none;
        }
    }

    .error-checkbox .control_indicator {
        border: 1px solid ${colors.red};
    }

    .control input:checked ~ .control_indicator:after {
        display: block;
    }

    .control-checkbox {
        .control_indicator {
            &:after {
                left: 5px;
                top: 1px;
                width: ${gutterSizes.small};
                height: 9px;
                border: 1px solid ${colors.white};
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 4.5rem;
                height: 4.5rem;
                margin-left: -1.3rem;
                margin-top: -1.3rem;
                border-radius: 3rem;
                opacity: 0.6;
                z-index: 99999;
                transform: scale(0);
            }
        }

        .link {
            font-size: ${fontSizes.base};
        }
    }
`;

export const PasswordAdviceContainer = styled.ul`
    margin-bottom: ${gutterSizes.large};
`;

export const PasswordAdvice = styled.li<{ isValidated: boolean }>`
    font-size: ${fontSizes.msmall};
    text-align: left;
    font-weight: ${fontWeights.normal};
    margin: 0;

    ${({ isValidated }) => isValidated && `color: ${colors.fgSuccess}`};

    &:before {
        content: "–";
        font-size: ${fontSizes.smaller};
        font-weight: ${fontWeights.bold};
        padding-right: ${gutterSizes.base};

        ${({ isValidated }) =>
            isValidated &&
            `content: '';
                background-image: url(${Tick});
                background-repeat: no-repeat;
                background-size: contain;
                height: ${gutterSizes.base};
                width: ${gutterSizes.base};
                display: inline-block;
            `};
    }
`;

export const PasswordStrength = styled.li<{ isValidated: boolean }>`
    font-size: ${fontSizes.msmall};
    text-align: left;
    font-weight: ${fontWeights.normal};
    margin: 0;

    ${({ isValidated }) =>
        isValidated
            ? `color: ${colors.fgSuccess}`
            : `
                color: ${colors.red};
            `};

    &:before {
        content: "–";
        font-size: ${fontSizes.smaller};
        font-weight: ${fontWeights.bold};
        padding-right: ${gutterSizes.base};

        ${({ isValidated }) =>
            isValidated
                ? `content: '';
                background-image: url(${Tick});
                background-repeat: no-repeat;
                background-size: contain;
                height: ${gutterSizes.base};
                width: ${gutterSizes.base};
                display: inline-block;
                margin-right: 6px;
            `
                : `
                content: 'x';
                color: ${colors.red};
            `};
    }
`;

export const CheckEmailTitle = styled.h2`
    font-size: ${fontSizes.pgLarge};
    font-weight: ${fontWeights.bold};
    margin-top: ${gutterSizes.xlarge};
`;

export const CheckEmailContent = styled.p`
    font-size: ${fontSizes.base};
    color: ${colorVariables.textNeutral};
`;

export const CheckEmailLink = styled(Link)`
    color: ${colors.blade};
    font-size: ${fontSizes.base};
    font-weight: ${fontWeights.bold};
`;

export const OpenEmailButton = styled(Button)`
    margin-bottom: 16px;
`;

export const ForgottenPasswordLink = styled.button`
    color: ${colorVariables.textLink};
    cursor: pointer;
    text-decoration: none;
    font-size: ${fontSizes.base};
    font-weight: 500;
    background: none;
    border: none;
    text-decoration: underline;
    margin-top: ${gutterSizes.medium};
    margin-bottom: ${gutterSizes.xlarge};
    float: left;
`;

export const ErrorBox = styled.div`
    background: ${colors.newDanger};
    padding: ${gutterSizes.large};
    border-radius: ${gutterSizes.base};
    margin: ${gutterSizes.xlarge} 0 ${gutterSizes.large} 0;
    font-size: 13px;
    display: flex;

    > span {
        color: ${colors.fgDanger};
        top: 4px;
        position: relative;
    }

    > p {
        margin: 0;
        padding-left: ${gutterSizes.base};
        text-align: left;
    }
`;

export const WarningBox = styled("div", {
    shouldForwardProp,
})<{
    isOnBottom?: boolean;
}>`
    background: ${colors.branch5};
    padding: ${gutterSizes.large};
    border-radius: ${gutterSizes.base};
    margin: ${gutterSizes.xlarge} 0 ${gutterSizes.large} 0;
    font-size: ${fontSizes.small};
    display: flex;

    ${({ isOnBottom }) =>
        isOnBottom && `margin: ${gutterSizes.base} 0 ${gutterSizes.large} 0`};

    > span {
        color: ${colors.fgDanger};
        top: ${gutterSizes.small};
        padding-right: ${gutterSizes.base};
        position: relative;
    }

    > p {
        margin: 0;
        padding-left: ${gutterSizes.base};
        text-align: left;
    }
`;

export const InfoBox = styled.div`
    background: ${colors.bgInfo};
    padding: ${gutterSizes.large};
    border-radius: ${gutterSizes.base};
    margin: ${gutterSizes.xlarge} 0 ${gutterSizes.large} 0;
    font-size: ${fontSizes.small};
    display: flex;

    > span {
        top: ${gutterSizes.small};
        padding-right: ${gutterSizes.base};
        position: relative;
    }

    > p {
        margin: 0;
        text-align: left;
    }
`;

export const EmailHighlight = styled.p`
    font-weight: ${fontWeights.lightBold};
    margin: 0;
    display: inline-block;
`;

export const FormPara = styled.p`
    font-size: ${fontSizes.base};
    color: ${colorVariables.textNeutral};
    margin: ${gutterSizes.base} 0 ${gutterSizes.xlarge} 0;

    a {
        font-size: ${fontSizes.base};
    }
`;
